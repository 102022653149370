import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Plaiz - Page Introuvable - Plaiz, le réseau social de la mode"/>
    <h1 className="p1">Page Introuvable</h1>

    <h4
      style={styles.backToHomeLink}
    >
      <Link to="/">Revenir à la page d'accueil</Link>
    </h4>
  </Layout>
)

export default NotFoundPage

const styles = {
  backToHomeLink: {
    color: "white",
  },
}